/* global config */

'use strict';

angular.module('managerApp.devicesManager').controller('DevicesManagerController',
        ($rootScope, $scope, $route, $cookies, $mdDialog, toast, $_PrepaidAccounts, _type) => {

    $scope.config = config;
    const TEMPLATES_DIR = config.app.templatesDir;
    const DEFAULT_ERROR_MESSAGE = config.app.defaultErrorMessage;


    const type = _type;
    const paymentRequestsPaginationCookie = 'pagination.paymentRequests.itemsPerPage';    

//------------------------------------------------------------------------------

    $scope.devicesManager = $rootScope.currentUser;
    $scope.currentUser = $rootScope.currentUser;
    $scope.isDevicesManagerSession = config.isDevicesManagerSession;

    $scope.client = $scope.devicesManager.Client;
    $scope.defaultDeviceType = 'terminal';

    $scope.paymentRequests = {};    

//------------------------------------------------------------------------------

    $scope._paymentRequests = {
        pagination: {
            currentPage: 1,
            maxSize: 5,
            itemsPerPage: $cookies.get(paymentRequestsPaginationCookie) || 10,
            totalItems: 0,
            isAll: false,
            isWebApp: false,
            status: 'all',
            pendingOnly: false,
            completedOnly: false,
            deviceType: null,
            setStatus: function () {
                this.pendingOnly = false;
                this.completedOnly = false;
                switch (this.status) {
                    case 'all':
                        break;
                    case 'completed':
                        this.completedOnly = true;
                        break;
                    case 'pending':
                        this.pendingOnly = true;
                        break;
                }
                this.run();
            },
            setDeviceType: function (deviceType) {
                this.deviceType = deviceType;
                this.isAll = deviceType === 'all';
                this.isWebApp = deviceType === 'webapp';
                this.run();
            },
            run: function () {
                $cookies.put(paymentRequestsPaginationCookie, this.itemsPerPage);                
                getPaymentRequests(this.deviceType, this.completedOnly, this.pendingOnly);
            }
        }
    };
    $scope._paymentRequests.pagination.setDeviceType($scope.defaultDeviceType); 

//------------------------------------------------------------------------------

    function getPaymentRequests(deviceType = 'terminal', completedOnly = false, pendingOnly = false) {
        let params = {
            deviceType: deviceType,
            prepaidAccountId: $scope.client.PrepaidAccount._id
        };
        if (completedOnly === true) {
            params.completed = 1;
        } else if (pendingOnly === true) {
            params.pending = 1;
        }
        $_PrepaidAccounts.getPaymentRequests(params, {
            page: $scope._paymentRequests.pagination.currentPage,
            pageSize: $scope._paymentRequests.pagination.itemsPerPage
        }).then((data) => {
            $scope._paymentRequests.pagination.totalItems = data.count;
            $scope.paymentRequests.items = [];
            $scope.paymentRequests.items = data.items || [];
            $scope.$apply();
        });
    }

    $scope.getPaymentRequests = (deviceType = 'terminal') => {
        $scope._paymentRequests.pagination.setDeviceType(deviceType);
        //getPaymentRequests(deviceType);
    };
    
    $scope.showValidatePaymentRequestPopup = (paymentRequest) => {
        $mdDialog.show({
            templateUrl: 'app-partner/templates/validate.payment.request.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {
                paymentRequest: paymentRequest,
                __paymentRequests: $scope._paymentRequests,
                prepaidAccount: $scope.client.PrepaidAccount._id
            },
            controller: ($scope, toast, paymentRequest, __paymentRequests, prepaidAccount) => {
                $scope.prepaidAccount = prepaidAccount;
                $scope.paymentRequest = paymentRequest;
                $scope.device = paymentRequest.Source,
                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        $_PrepaidAccounts.validateOrRejectPaymentRequest(prepaidAccount, paymentRequest._id, 'validate', {
                            deviceId: $scope.device._id,
                            deviceType: $scope.device.type,
                            method: $scope.paymentRequest.method
                        }).then((res) => {
                            $mdDialog.hide();
                            __paymentRequests.pagination.run();
                            toast.show('Le paiement a bien été validé.', 'success');
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    } else {
                        $mdDialog.hide();
                    }//
                };
            }
        }).then((answer) => {
            console.log('answer : ' + answer);
        }, () => {
            console.log('You cancelled the dialog.');
        });
    };

    $scope.reject = (paymentRequest) => {
        let prepaidAccount = $scope.client.PrepaidAccount._id;
        let device = paymentRequest.Source;
        if (confirm('Voulez vous vraiment annuler cette demande de paiement ?')) {
            $_PrepaidAccounts.validateOrRejectPaymentRequest(prepaidAccount, paymentRequest._id, 'reject', {
                deviceId: device._id,
                deviceType: device.type
            }).then((res) => {
                $scope._paymentRequests.pagination.run();
                toast.show('La demande de paiement a bien été refusée.', 'success');
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }
    };

    $scope.showLogoutPopup = ($event) => {
        $mdDialog.show({
            targetEvent: $event,
            parent: angular.element(document.body),
            templateUrl: 'components/left-sidebar/logout.html',
            controller: ($scope, $location, $mdDialog) => {
                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        $location.path('/logout');
                    }
                    $mdDialog.cancel();
                };
            }
        });
    };

//------------------------------------------------------------------------------

});